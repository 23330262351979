export default {
  siteTitle: 'Get bonus',
  blogerName: 'RSQUAD',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@kytaaaaa3497'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@LokJavi'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@cristian_a_secas_'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@BaconSlotsCL'
    }
  ],
  projects: [
    {
      name: 'jet',
      url: 'https://jetcasino.life/cdecaa382',
      gameTitle: 'JET AIR (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c09953b55',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c0b07be3d',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c573bd594',
      gameTitle: 'Doors Of Sol (BGaming)',
    }
  ],
  headerText: 'Regístrate con mi código promocional <strong>RSQUAD</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Tu código promocional',
  gameBonus: 'Bono en el juego',
  promocode: 'RSQUAD',
  casinoBonus1: '<strong>100% + 500FS</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado en el portapapeles',
  theme: 'dark'
};
